<template>
    <div class="row justify-content-md-center">
        <div v-if="false" class="col-md-8 col-xs-12">
            <div class="col-12 login-title-div">
                <div class="login-title">Вход</div>
            </div>
            <ValidationObserver slim v-slot="{ invalid, handleSubmit }">
                <form @submit.prevent="handleSubmit(login)">
                    <div class="form-group">
                        <ValidationProvider name="email" rules="required">
                            <input v-model="email" name="emailInput" type="email" class="login-form-inputs"
                                   id="login-input"
                                   aria-describedby="emailHelp"
                                   placeholder="Логин"/>
                        </ValidationProvider>
                    </div>
                    <div class="form-group">
                        <ValidationProvider name="password" rules="required">
                            <input v-model="password" name="passwordInput" type="password" class="login-form-inputs"
                                   id="password-input" placeholder="Пароль"/>
                        </ValidationProvider>
                    </div>
                    <div class="reset-password-div">
                        <div class="forget-password">
                            <p>Забыли пароль?</p>
                        </div>
                        <div class="reset-password fl-sm">
                            <router-link :to="{name: 'RecoverPassword'}">
                                Восстановить
                            </router-link>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <button :disabled="invalid" class="btn login-btn fl-sm">Вход</button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {mapActions} from 'vuex'

export default {
    name: "Login",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            email: '',
            password: '',
        }
    },
    methods: {
        ...mapActions('account', ['authenticate']),
        async login() {
            await this.authenticate({
                username: this.email,
                password: this.password
            });
        }
    },
    metaInfo() {
        return {
            title: `Авторизация - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>
.login-title {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0;
}

.login-form-inputs {
    width: 302px;
    height: 54px;
    background-color: #ffffff;
    border: solid 1px #ededed;
    padding: 10px 15px;
}

.forget-password p {
    width: 100px;
    height: 14px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #b3b3b3;
}

.forget-password {
    float: left;
}

.reset-password a {
    width: 82px;
    height: 14px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #e5173f;
    margin-left: 10px;
}

.reset-password-div {
    margin-top: 50px;
    margin-bottom: 60px;
}

.login-btn {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 141px;
    height: 36px;
    border-radius: 2px;
    background-color: #e5173f;
    cursor: pointer;
}

header form {
    max-width: 35rem;
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
}

.form-group {
    margin-bottom: 0;
}

.form input {
    width: 100%;
    height: 54px;
    background-color: #ffffff;
    border: solid 1px #ededed;
    padding-left: 19px;
}

.form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    width: 41px;
    height: 16px;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #71706f;
}

.login-title-div {
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    header form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .reset-password-div {
        display: flex;
        margin-bottom: 20px;
        /*width: 302px;*/
    }

    .login-title-div {
        margin-bottom: 30px;
    }
}

@media (max-width: 576px) {

    .reset-password {
        float: right;
    }

    .reset-password-div {
        margin-top: 30px;
        margin-bottom: 10px;
    }
}

/*Хак для IE*/
.form input:-ms-input-placeholder {
    width: 100%;
    height: 54px;
    background-color: #ffffff;
    border: solid 1px #ededed;
    padding-left: 19px;
}
</style>
